@import 'style/variables';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-bottom: 0;
  }

  .description {
    color: $middle-grey;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.5rem;
  }

  .actions {
    :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
