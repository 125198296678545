@import 'style/variables';
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  animation: spinning infinite 1s linear forwards;
  &.done {
    animation-play-state: paused;
  }
}
