//Define all local fonts in the assets/fonts folder here

@font-face {
  font-family: Metropolis;
  src: url('/assets/fonts/metropolis/Metropolis-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Metropolis;
  src: url('/assets/fonts/metropolis/Metropolis-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
    font-family: Metropolis;
    src: url('/assets/fonts/metropolis/Metropolis-Black.otf');
    font-weight: 900;
    font-style: normal;
}
