@use '../input/input.module.scss';

div.container {
  display: flex;
  flex-direction: column;
  .input {
    flex: 1;
    min-height: 2.82rem;
    vertical-align: top;
  }
}
