@import 'style/variables';

.bottomNav {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 5rem;
  background: $muiSurface4;
  transition: transform 0.2s ease;
  overflow: hidden;
  &.hidden {
    transform: translateX(-100%);
  }
  > .bottomNavItem {
    flex: 1;
    max-width: 12rem;
    font-weight: 500;
    > svg + span {
      position: relative;

      line-height: 1rem;
      margin-top: 0.25rem;
      transition: none;

      &::after {
        position: absolute;
        bottom: 1rem;
        left: 0;
        content: ' ';
        background-color: inherit;
        height: 2rem;
        width: 100%;
        padding: 0.25rem 1.25rem;
        border-radius: 1.25rem;
        z-index: -1;
      }
    }

    &.active {
      color: $muiPrimary;

      > svg + span::after {
        background-color: $muiSecondaryContainer;
      }
    }
  }
}
