@import 'style/variables';

.activator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  &:hover {
    cursor: pointer;
    background-color: $muiSurface3;
  }
  &.open {
    background-color: $muiSurface2;
  }
}

.chat,
.chatRoom {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 4rem;
  bottom: 0;
  width: 100%;
  right: -100%;
  transition: right 0.2s;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    .title {
      padding-left: 0.75rem;
    }
    /* M3/Elevation Dark/1 */
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
  }

  &.open {
    right: 0;
  }
  @include breakpoint(md) {
    width: 20rem;
    right: -20rem;
  }
}
.chat {
  z-index: 1000;
  .selector {
    padding: 1rem;
    > .tournamentInput {
      width: 100%;
    }
  }
  .chatList {
    padding: 1rem;
    overflow-y: scroll;
    overscroll-behavior: contain;

    > .chatRoomItem {
      background-color: $muiSurface2;
      padding: 1rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .discordButton {
      margin-top: 1rem;
    }
  }
}

.chatRoom {
  z-index: 1100;
  .messageList {
    flex: 1;
    padding: 1rem;
    overflow: auto;
    height: calc(100% - 8.5rem);
    overscroll-behavior: contain;
  }
  .messageInput {
    padding: 0 1rem 1rem;
  }
}
